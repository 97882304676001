


























import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { Namespace } from "@/store/types";
import { Action, Getter } from "vuex-class";
import {
  Conference,
  EndConferencePayload,
  CoreVicoActions,
} from "@/spect8-core-vue/src/types";
import TestCall from "./TestCall.vue";
import CloseDialog from "@/spect8-core-vue/src/components/CloseDialog.vue";
import { RTCSlot } from "@/spect8-core-vue/src/stores/vico/RtcSlot";

@Component({ components: { TestCall, CloseDialog } })
export default class TestCallDialog extends Vue {
  @Ref("closeDialog") closeDialog!: CloseDialog;
  @Ref("testCall") testCall!: TestCall;

  @Action(`${Namespace.Conference}/setTestCallDialog`)
  setTestCallDialog!: (dialog: boolean) => void;
  @Action(CoreVicoActions.EndConference)
  endConference!: (payload: EndConferencePayload) => void;

  @Getter(`${Namespace.Conference}/testCallDialog`)
  testCallDialog!: boolean;
  @Getter(`${Namespace.Conference}/testConference`)
  testConference!: Conference;
  @Getter(`${Namespace.Conference}/testCall`)
  getTestCall!: RTCSlot | null;

  get dialog(): boolean {
    return this.testCallDialog;
  }
  set dialog(dialog: boolean) {
    this.setTestCallDialog(dialog);
  }

  @Watch("dialog")
  onDialogChange(dialog: boolean) {
    if (!dialog && this.testConference) {
      this.endConference({
        tenantId: this.testConference.tenantId,
        broadcastId: this.testConference.broadcastId,
        conferenceId: this.testConference.id,
      });
    }
    console.debug(`${dialog ? "Opening" : "Closing"} the call dialog`);
  }

  dialogWidth = "80%";
  onResize() {
    this.dialogWidth = window.innerWidth > 960 ? "80%" : "90%";
  }

  setup(): Promise<boolean> {
    return this.testCall.setup();
  }

  // this feels wrong here
  @Watch("testConference")
  onTestConfChanged(conf: Conference) {
    if (!conf) this.onClose();
  }

  async onCancel() {
    const res = await this.closeDialog.show();
    if (res) {
      this.dialog = false;
    }
  }

  async onClose() {
    this.dialog = false;
  }
}
