var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"page-row",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.broadcasts,"return-object":"","item-text":"broadcastName"},model:{value:(_vm.selectedBroadcast),callback:function ($$v) {_vm.selectedBroadcast=$$v},expression:"selectedBroadcast"}}),_c('v-list',{staticClass:"qalist-status-tabs d-flex background"},_vm._l((Object.values(_vm.QuestionStatus)),function(status,index){return _c('v-list-item',{key:index,staticClass:"justify-center",class:_vm.selectedStatus === status ? ' selected' : '',on:{"click":function($event){return _vm.setStatus(status)}}},[_vm._v(" "+_vm._s(_vm.statusLabel(status))+" "),(status === _vm.QuestionStatus.ANSWERING && _vm.liveResourceCount > 0)?_c('v-icon',{staticClass:"ml-1",staticStyle:{"transform":"scale(1.75)"},attrs:{"color":"red"}},[_vm._v(" mdi-circle-medium ")]):_vm._e()],1)}),1),_c('h2',{staticClass:"title my-3"},[_vm._v(_vm._s(_vm.$t("qa.listTitle")))]),_c('v-data-table',{staticClass:"qalist-data-table",class:("status-" + (_vm.selectedStatus.toLowerCase())),attrs:{"items":_vm.filteredQuestions,"headers":_vm.headers,"show-select":"","hide-default-header":"","mobile-breakpoint":"0","items-per-page":_vm.itemsPerPage,"footer-props":{
        'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
      }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var on = ref.on;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-start",staticStyle:{"width":"1px","min-width":"1px"},attrs:{"role":"columnheader","scope":"col","aria-label":""}},[_c('v-simple-checkbox',{on:{"click":function () { return on['toggle-select-all'](_vm.toggleSelectAll); }},model:{value:(_vm.toggleSelectAll),callback:function ($$v) {_vm.toggleSelectAll=$$v},expression:"toggleSelectAll"}})],1),_c('th',[(
                  !_vm.selectedRows.length &&
                  _vm.selectedStatus === _vm.QuestionStatus.APPROVED
                )?_c('v-list',{staticClass:"table-header-actions"},[(_vm.enabledActions.includes('add-in-person-contribution'))?_c('v-list-item',{on:{"click":function($event){_vm.contributionDialog = true}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-human-greeting ")]),_c('span',[_vm._v(_vm._s(_vm.$t("qa.inPersonContributionActionText")))])],1):_vm._e()],1):_c('v-list',{staticClass:"table-header-actions",attrs:{"disabled":!_vm.selectedRows.length}},[(_vm.enabledActions.includes('approve'))?_c('v-list-item',{on:{"click":function($event){return _vm.bulkUpdateQuestionStatus(_vm.QuestionStatus.APPROVED)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-thumb-up ")]),_c('span',[_vm._v(_vm._s(_vm.$t("qa.approveActionText")))])],1):_vm._e(),(_vm.enabledActions.includes('dismiss'))?_c('v-list-item',{on:{"click":function($event){return _vm.bulkUpdateQuestionStatus(_vm.QuestionStatus.DISMISSED)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-thumb-down ")]),_c('span',[_vm._v(_vm._s(_vm.$t("qa.dismissActionText")))])],1):_vm._e(),(_vm.enabledActions.includes('live'))?_c('v-list-item',{on:{"click":function($event){return _vm.bulkUpdateQuestionStatus(_vm.QuestionStatus.ANSWERING)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-broadcast ")]),_c('span',[_vm._v(_vm._s(_vm.$t("qa.answeringActionText")))])],1):_vm._e(),(_vm.enabledActions.includes('complete'))?_c('v-list-item',{on:{"click":function($event){return _vm.bulkUpdateQuestionStatus(_vm.QuestionStatus.ANSWERED)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-check-all ")]),_c('span',[_vm._v(_vm._s(_vm.$t("qa.answeredActionText")))])],1):_vm._e()],1)],1)])])]}},{key:"item",fn:function(ref){
                var isSelected = ref.isSelected;
                var select = ref.select;
                var item = ref.item;
return [_c('tr',{staticClass:"table-row-item"},[_c('td',{staticClass:"text-start"},[_c('v-simple-checkbox',{attrs:{"value":isSelected},on:{"click":function () { return select(!isSelected); }}})],1),_c('td',{staticClass:"text-start"},[_c('div',{staticClass:"d-flex py-3"},[_c('div',{staticClass:"flex-grow-1 mr-3"},[_c('div',{staticClass:"mb-1 d-flex"},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(item.user.displayName)+" "),_c('v-icon',{staticClass:"ml-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.getQuestionTypeIcon(item.type))+" ")]),_c('span',{staticClass:"ml-3 font-weight-light",staticStyle:{"opacity":"60%"}},[_vm._v(_vm._s(item.category))])],1),_c('v-spacer'),_vm._v(" "+_vm._s(_vm.getQuestionAskedTime(item))+" ")],1),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.content))]),(item.answer && _vm.addCommentItemId !== item.id)?_c('p',{staticClass:"mt-2 answer"},[_vm._v(" "+_vm._s(item.answer)+" ")]):_vm._e(),(_vm.addCommentItemId === item.id)?_c('v-textarea',{staticClass:"ma-0 pa-0 mt-2",attrs:{"append-icon":"mdi-send","auto-grow":"","rows":"1","placeholder":_vm.$t('qa.typeAnswer')},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.saveQuestionAnswer(item)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","disabled":!_vm.commentInput.length},on:{"click":function($event){return _vm.saveQuestionAnswer(item)}}},[_c('v-icon',[_vm._v("mdi-send")])],1)]},proxy:true}],null,true),model:{value:(_vm.commentInput),callback:function ($$v) {_vm.commentInput=$$v},expression:"commentInput"}}):_vm._e()],1),_c('div',{key:_vm.selectedStatus,staticClass:"table-row-item-actions"},[(
                    _vm.enabledFeatures.GEMA_QA_MANAGEMENT &&
                    item.type === _vm.QuestionType.VIDEO &&
                    _vm.enabledActions.includes('video')
                  )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.startVideoCall(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-message-video ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("qa.startVideoCall")))])]):_vm._e(),(_vm.enabledActions.includes('approve'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.updateQuestionStatus(item, _vm.QuestionStatus.APPROVED)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-thumb-up ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("qa.approveActionText")))])]):_vm._e(),(_vm.enabledActions.includes('dismiss'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.updateQuestionStatus(item, _vm.QuestionStatus.DISMISSED)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-thumb-down ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("qa.dismissActionText")))])]):_vm._e(),(_vm.enabledActions.includes('reply'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20","color":_vm.addCommentItemId === item.id ? 'brand' : null},on:{"click":function($event){return _vm.toggleAddCommentField(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-reply ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("qa.commentActionText")))])]):_vm._e(),(_vm.enabledActions.includes('live'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.updateQuestionStatus(item, _vm.QuestionStatus.ANSWERING)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-broadcast ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("qa.answeringActionText")))])]):_vm._e(),(_vm.enabledActions.includes('complete'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.updateQuestionStatus(item, _vm.QuestionStatus.ANSWERED)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-all ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("qa.answeredActionText")))])]):_vm._e(),(!_vm.enabledFeatures.GEMA_QA_MANAGEMENT)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.removeQuestion(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("qa.deleteActionText")))])]):_vm._e()],1)])])])]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('TestCallDialog',{ref:"testCallDialog"}),_c('InPersonContributionDialog',{attrs:{"dialog":_vm.contributionDialog,"selectedBroadcast":_vm.selectedBroadcast},on:{"closeDialog":function($event){_vm.contributionDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }