

















import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import { RTCSlot } from "../../stores/vico/RtcSlot";

@Component({
  components: {},
})
export default class Viewer extends Vue {
  @Prop({ default: true }) readonly controls!: boolean;
  @Prop({ default: true }) readonly autoplay!: boolean;
  @Prop({ default: false }) readonly muted!: boolean;
  @Prop({ default: true }) readonly loop!: boolean;
  @Prop({ required: true }) readonly rtc!: RTCSlot;

  @Ref("video") videoElement!: HTMLMediaElement;

  @Watch("rtc", { immediate: true, deep: true })
  onRtcChange(slot: RTCSlot): void {
    if (this.videoElement) {
      if (!slot || !slot.streaming) this.videoElement.srcObject = null;
      else this.videoElement.srcObject = slot.mediaStream ?? null;
    }
  }

  videoStyle: any = {
    backgroundColor: this.rtc?.rtcInfo?.slotId === "main" ? "black" : "",
  };
}
