













































import { Component, Vue, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  ConnectionState,
  CoreMediaDevicesActions,
  CoreMediaDevicesGetters,
  CoreVicoActions,
} from "@/spect8-core-vue/src/types";
import MediaSetup from "@/spect8-core-vue/src/components/mediaDevices/MediaSetup.vue";

@Component({
  components: { MediaSetup },
})
export default class ConferenceDialog extends Vue {
  @Prop({ default: false }) readonly broadcasting!: boolean;

  @Getter(CoreMediaDevicesGetters.ConnectionState)
  readonly getConnectionState!: ConnectionState;
  @Getter(CoreMediaDevicesGetters.BroadcastActive)
  readonly getBroadcastActive!: boolean;

  @Action(CoreMediaDevicesActions.ConnectionState)
  setConnectionState!: (connectionState: ConnectionState) => void;
  @Action(CoreMediaDevicesActions.BroadcastActive)
  setBroadcastActive!: (showDialog: boolean) => void;
  @Action(CoreVicoActions.ToggleIncomingAudio)
  toggleIncomingAudioAction!: () => void;

  get broadcastActive(): boolean {
    return this.getBroadcastActive;
  }
  set broadcastActive(active: boolean) {
    this.setBroadcastActive(active);
  }

  get connectionState(): ConnectionState {
    return this.getConnectionState;
  }
  set connectionState(connectionState: ConnectionState) {
    this.setConnectionState(connectionState);
  }

  get title(): string {
    switch (this.connectionState) {
      case ConnectionState.TEST_QUEUE:
        return "Warteschlange für den Testanruf";
      case ConnectionState.TEST_CALL:
        return "Testanruf";
      case ConnectionState.LIVE_QUEUE:
        return "Warteschlange für den Testanruf";
      case ConnectionState.LIVE:
        return "Live";
      default:
        return "Kamera und Mikrofon einrichten";
    }
  }

  get body(): string {
    switch (this.connectionState) {
      case ConnectionState.TEST_QUEUE:
        return "Bitte warten Sie. Ein Mitarbeiter wird Ihre Anfrage sobald wie möglich bearbeiten.";
      case ConnectionState.TEST_CALL:
        return "";
      case ConnectionState.LIVE_QUEUE:
        return "Bitte halten Sie sich bereit. Sie werden in kürze live geschalten.";
      case ConnectionState.LIVE:
        return "";
      default:
        return "Bitte geben Sie der Seite Zugriff auf Ihre Kamera und das Mikrofon. Stellen Sie sicher, dass Sie die richtigen Geräte ausgewählt haben, damit andere Teilnehmer Sie sehen und hören können. Klicken Sie im Anschluss auf 'Bereit für Testanruf'. Einer unserer Mitarbeiter wird Ihre Anfrage sobald wie möglich bearbeiten.";
    }
  }

  get buttonText(): string {
    switch (this.connectionState) {
      case ConnectionState.TEST_QUEUE:
        return "Testanruf beenden";
      case ConnectionState.TEST_CALL:
        return "Testanruf beenden";
      case ConnectionState.LIVE_QUEUE:
        return "Wortmeldung zurückziehen";
      case ConnectionState.LIVE:
        return "Wortmeldung zurückziehen";
      default:
        return "Bereit für Testanruf";
    }
  }

  livestreamTone = true;

  closeDialog(): void {
    this.broadcastActive = false;
    this.connectionState = 0;
    this.$emit("stop-broadcast");
  }

  // this should not actually change states
  onButton(): void {
    switch (this.connectionState) {
      case ConnectionState.SETUP:
        this.$emit("broadcast");
        break;

      default:
        break;
    }
    this.connectionState = (this.connectionState + 1) % 4;
  }
  toggleIncomingAudio(): void {
    this.toggleIncomingAudioAction();
    this.livestreamTone = !this.livestreamTone;
  }
}
