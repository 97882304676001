


























import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class CloseDialog extends Vue {
  @Prop({ default: "Are you sure?" }) title!: string;
  @Prop({ default: "" }) body!: string;
  @Prop({ default: "Cancel" }) cancelBtn!: string;
  @Prop({ default: "Confirm" }) confirmBtn!: string;
  @Prop({ default: "400px" }) maxWidth!: string;

  dialog = false;

  resolve?: (val: boolean) => void;
  reject?: (reason: any) => void;

  show(): Promise<boolean> {
    this.dialog = true;

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  confirm(): void {
    this.dialog = false;
    if (this.resolve) this.resolve(true);
  }
  cancel(): void {
    this.dialog = false;
    if (this.resolve) this.resolve(false);
  }
}
