





























import { Namespace } from "@/store/types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { Broadcast } from "../Broadcast/broadcast/types";
import { CreateQuestionPayload, QAAction, QuestionType } from "./types";

@Component
export default class InPersonContributionDialog extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop({ required: true }) selectedBroadcast!: Broadcast;

  @Action(QAAction.CreateQuestion, { namespace: Namespace.Qa })
  createQuestion!: (questionDto: CreateQuestionPayload) => Promise<void>;

  contentInput = "";

  async saveQuestion() {
    if (!this.contentInput) return;

    try {
      await this.createQuestion({
        broadcastId: this.selectedBroadcast.id,
        dto: {
          content: this.contentInput,
          note: "",
          type: QuestionType.IN_PERSON,
        },
      });
      this.contentInput = "";
      this.$emit("closeDialog");
    } catch (error) {
      this.$toast.error("Something went wrong, please try again.");
    }
  }
}
