
















































































































































































































































































































import { formatIsoTimeString } from "@/helpers";
import { NchanAction, NchanClientActionPayload } from "@/store/nchan/types";
import { Namespace } from "@/store/types";
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  Broadcast,
  BroadcastActions,
  BroadcastGetters,
} from "../Broadcast/broadcast/types";
import {
  QAAction,
  QAGetter,
  Question,
  QuestionStatus,
  QuestionType,
  UpdateQuestionPayload,
} from "../Qa/types";
import InPersonContributionDialog from "./InPersonContributionDialog.vue";
import ConferenceDialog from "../Conference/components/ConferenceDialog.vue";
import TestCall from "../Conference/components/TestCall.vue";
import TestCallDialog from "../Conference/components/TestCallDialog.vue";
import { RTCSlot } from "@/spect8-core-vue/src/stores/vico/RtcSlot";
import { StartVideoCallPayload, VerifyUserPayload } from "../Conference/types";
import { vicoService } from "@/spect8-core-vue/src/services/vico";
import { ConfigGetters, Features } from "@/config/types";
import {
  CoreConfigGetters,
  CoreMediaDevicesGetters,
  TenantConfig,
} from "@/spect8-core-vue/src/types";

@Component({
  components: {
    InPersonContributionDialog,
    ConferenceDialog,
    TestCall,
    TestCallDialog,
  },
})
export default class QaList extends Vue {
  @Ref("testCallDialog") testCallDialog!: TestCallDialog;
  @Getter(`${Namespace.Qa}/${QAGetter.Questions}`)
  readonly questions!: Question[];
  @Getter(`${Namespace.Qa}/${QAGetter.SelectedBroadcastId}`)
  readonly selectedBroadcastId!: string;

  @Action(`${Namespace.Qa}/${QAAction.FetchQuestions}`)
  fetchQuestions!: (broadcastId: string) => Promise<void>;
  @Action(`${Namespace.Qa}/${QAAction.UpdateQuestion}`)
  updateQuestion!: (payload: UpdateQuestionPayload) => Promise<void>;
  @Action(`${Namespace.Qa}/${QAAction.DeleteQuestion}`)
  deleteQuestion!: (questionId: string) => Promise<void>;
  @Action(`${Namespace.Qa}/${QAAction.SetSelectedBroadcastId}`)
  setSelectedBroadcastId!: (broadcastId: string) => Promise<void>;

  @Action(`${Namespace.Nchan}/${NchanAction.AddClient}`)
  addNchanClient!: (clientPayload: NchanClientActionPayload) => Promise<void>;
  @Action(`${Namespace.Nchan}/${NchanAction.RemoveClient}`)
  deleteNchanClient!: (
    clientPayload: NchanClientActionPayload
  ) => Promise<void>;

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Action(BroadcastActions.GetBroadcasts)
  fetchBroadcasts!: () => Promise<void>;

  @Getter(ConfigGetters.EnabledFeatures)
  readonly enabledFeatures!: Features;

  @Getter(CoreConfigGetters.TenantConfig)
  readonly tenantConfig!: TenantConfig;

  QuestionStatus = QuestionStatus;
  QuestionType = QuestionType;
  selectedBroadcast: Broadcast | null = null;
  selectedStatus = QuestionStatus.PENDING;

  selectedRows: Question[] = [];
  toggleSelectAll = false;

  contributionDialog = false;

  addCommentItemId = "";
  commentInput = "";

  itemsPerPage = 10;
  headers = [
    {
      text: "",
      align: "start",
      sortable: false,
      value: "content",
    },
  ];

  async created() {
    await this.fetchBroadcasts();

    if (this.broadcasts.length) {
      this.selectedBroadcast = this.selectedBroadcastId
        ? this.broadcasts.find(
            (broadcast) => broadcast.id === this.selectedBroadcastId
          ) || this.broadcasts[0]
        : this.broadcasts[0];
    }
  }

  @Action(`${Namespace.Conference}/verifyUserTracks`)
  verifyUserTracks!: (pl: VerifyUserPayload) => Promise<boolean | void>;

  verifyTrackInterval?: ReturnType<typeof setInterval>;

  async mounted() {
    if (this.verifyTrackInterval) clearInterval(this.verifyTrackInterval);
    this.verifyTrackInterval = setInterval(async () => {
      this.questions
        .filter(
          (q) =>
            q.type === "VIDEO" &&
            ![QuestionStatus.ANSWERED, QuestionStatus.DISMISSED].includes(
              q.status
            )
        )
        .forEach((q) => {
          this.verifyUserTracks({ question: q, userId: q.user.userId });
        });
    }, 2000);
  }

  beforeDestroy() {
    clearInterval(this.verifyTrackInterval);
  }

  async destroyed() {
    if (!this.selectedBroadcast) return;

    await this.deleteNchanClient({
      namespace: Namespace.Qa,
      nchanUrlPath: [
        this.tenantConfig.tenantId,
        this.selectedBroadcast.id,
      ].join(":"),
    });
  }

  @Watch("selectedBroadcast")
  async onSelectedBroadcastChange(
    newValue: Broadcast,
    oldBroadcastValue: Broadcast | null
  ) {
    if (!this.selectedBroadcast) return;

    if (this.selectedBroadcastId !== this.selectedBroadcast.id) {
      this.setSelectedBroadcastId(this.selectedBroadcast.id);
    }

    if (this.tenantConfig.tenantId) {
      if (oldBroadcastValue) {
        await this.deleteNchanClient({
          namespace: Namespace.Qa,
          nchanUrlPath: [this.tenantConfig.tenantId, oldBroadcastValue.id].join(
            ":"
          ),
        });
      }

      await this.addNchanClient({
        namespace: Namespace.Qa,
        nchanUrlPath: [
          this.tenantConfig.tenantId,
          this.selectedBroadcast.id,
        ].join(":"),
      });
    }

    this.fetchQuestions(this.selectedBroadcast.id);
    this.resetTableFields();
  }

  @Watch("selectedRows")
  onSelectedRowChange(newValue: [], oldValue: []) {
    const toggleAllCount =
      this.filteredQuestions.length < this.itemsPerPage
        ? this.filteredQuestions.length
        : this.itemsPerPage;

    if (!this.filteredQuestions.length) {
      this.toggleSelectAll = false;
      return;
    }

    if (this.toggleSelectAll && oldValue.length > newValue.length) {
      this.toggleSelectAll = false;
    } else if (newValue.length === toggleAllCount) {
      this.toggleSelectAll = true;
    }
  }

  get filteredQuestions(): Question[] {
    return this.questions
      .filter((question) => question.status === this.selectedStatus)
      .sort((a, b) => {
        let aUser = a.user.displayName.toLowerCase(),
          bUser = b.user.displayName.toLowerCase();
        if (
          (aUser.includes("dpma") ||
            aUser.includes("kpmg") ||
            aUser.includes("gema")) &&
          !(
            bUser.includes("dpma") ||
            bUser.includes("kpmg") ||
            bUser.includes("gema")
          )
        ) {
          return -1;
        }
        if (
          (bUser.includes("dpma") ||
            bUser.includes("kpmg") ||
            bUser.includes("gema")) &&
          !(
            aUser.includes("dpma") ||
            aUser.includes("kpmg") ||
            aUser.includes("gema")
          )
        ) {
          return 1;
        }
        let aCategory = a.category,
          bCategory = b.category;
        if (
          aCategory == "Antrag zur Geschäftsordnung" &&
          bCategory != "Antrag zur Geschäftsordnung"
        ) {
          return -1;
        }
        if (
          bCategory == "Antrag zur Geschäftsordnung" &&
          aCategory != "Antrag zur Geschäftsordnung"
        ) {
          return 1;
        }
        return Date.parse(a.createdAt) - Date.parse(b.createdAt);
      });
  }

  get enabledActions(): string[] {
    switch (this.selectedStatus) {
      case QuestionStatus.PENDING:
        return ["approve", "dismiss", "reply", "video"];
      case QuestionStatus.APPROVED:
        return this.liveResourceCount > 0
          ? ["add-in-person-contribution", "dismiss", "reply", "complete"]
          : [
              "live",
              "add-in-person-contribution",
              "dismiss",
              "reply",
              "complete",
            ];
      case QuestionStatus.DISMISSED:
        return ["approve", "reply"];
      case QuestionStatus.ANSWERING:
        return ["complete"];
    }

    return [];
  }

  getQuestionTypeIcon(type: QuestionType): string {
    switch (type) {
      case QuestionType.TEXT:
        return "mdi-message-text";
      case QuestionType.VIDEO:
        return "mdi-video";
      case QuestionType.IN_PERSON:
        return "mdi-human-greeting";
    }
  }

  getQuestionAskedTime(question: Question) {
    return formatIsoTimeString(new Date(question.createdAt));
  }

  setStatus(status: QuestionStatus) {
    this.selectedStatus = status;
    this.resetTableFields();
  }

  resetTableFields() {
    this.addCommentItemId = "";
    this.commentInput = "";
    this.selectedRows = [];
    this.toggleSelectAll = false;
  }

  @Action(`${Namespace.Conference}/startVideoCall`)
  startVideoCallAction!: (
    payload: StartVideoCallPayload
  ) => Promise<RTCSlot | void>;

  @Getter(`${Namespace.Conference}/testCall`)
  testCall?: RTCSlot;
  @Action(`${Namespace.Conference}/setTestCallDialog`)
  setTestCallDialog!: (dialog: boolean) => void;
  @Action(`${Namespace.Conference}/setSetupDialog`)
  setSetupDialog!: (dialog: boolean) => void;
  @Getter(CoreMediaDevicesGetters.Stream)
  stream?: MediaStream;

  callDialog = false;

  startVideoCall(question: Question) {
    this.setTestCallDialog(true);
    this.$nextTick()
      .then(() => this.testCallDialog.setup())
      .then((done) => {
        if (done)
          return this.startVideoCallAction({
            question,
            broadcastId: this.selectedBroadcastId,
          });
      })
      .catch((err) => console.error(err));
  }

  async updateQuestionStatus(question: Question, status: QuestionStatus) {
    this.updateQuestion({
      questionId: question.id,
      patchDto: {
        status,
      },
    });

    // Video Conference
    if (question.type === QuestionType.VIDEO) {
      if (status === QuestionStatus.ANSWERING) {
        const { trackinfos } = await vicoService.getUserTracks(
          question.user.userId,
          question.user.tenantId
        );
        const audio = trackinfos.find((t) => t.kind === 1);
        const video = trackinfos.find((t) => t.kind === 2);
        if (!audio) throw Error("Missing audio track");
        if (!video) throw Error("Missing video track");

        const { conferences } = await vicoService.getConferences(
          question.user.tenantId
        );
        const filteredConferences = conferences.filter(
          (c) => c.broadcastId === this.selectedBroadcastId
        );
        //todo this will be a selection
        const conferenceId = filteredConferences[0].id;

        vicoService.updateSlotTracks({
          tenantId: question.user.tenantId,
          conferenceId,
          userId: question.user.userId,
          slotId: "remote",
        });
      }

      if (status === QuestionStatus.ANSWERED) {
        const { conferences } = await vicoService.getConferences(
          question.user.tenantId
        );
        const filteredConferences = conferences.filter(
          (c) => c.broadcastId === this.selectedBroadcastId
        );
        const conferenceId = filteredConferences[0].id;

        await vicoService.updateSlotTracks({
          tenantId: question.user.tenantId,
          conferenceId,
          slotId: "remote",
          userId: question.user.userId,
        });
        await vicoService.updateSlotTracks({
          userId: "",
          tenantId: question.user.tenantId,
          conferenceId,
          slotId: "remote",
        });
      }
    }

    this.selectedRows = this.selectedRows.filter(
      (selected) => selected.id !== question.id
    );
  }

  removeQuestion(questionId: string) {
    this.deleteQuestion(questionId);
    this.selectedRows = this.selectedRows.filter(
      (selected) => selected.id !== questionId
    );
  }

  async bulkUpdateQuestionStatus(status: QuestionStatus) {
    if (!this.selectedRows.length) return;

    const promises: Promise<void>[] = [];
    const promiseMap: Record<string, string> = {}; // Map index, to questionId
    let selectedRows = this.selectedRows;

    selectedRows.forEach((question, index) => {
      promises.push(
        this.updateQuestion({
          questionId: question.id,
          patchDto: {
            status,
          },
        })
      );
      promiseMap[index] = question.id;
    });

    const settledPromises = await Promise.allSettled(promises);
    settledPromises.forEach((promise, index) => {
      if (promise.status === "fulfilled") {
        selectedRows = selectedRows.filter(
          (selected) => selected.id !== promiseMap[index]
        );
      }
    });

    this.selectedRows = selectedRows;

    if (selectedRows.length > 0) {
      this.$toast.error(this.$i18n.t("qa.bulkUpdateError").toString());
    }
  }

  toggleAddCommentField(question: Question) {
    if (this.addCommentItemId === question.id) {
      this.commentInput = "";
      this.addCommentItemId = "";
    } else {
      this.commentInput = question.answer || "";
      this.addCommentItemId = question.id;
    }
  }

  saveQuestionAnswer(question: Question) {
    if (!this.commentInput.length) return;

    this.updateQuestion({
      questionId: question.id,
      patchDto: {
        answer: this.commentInput,
      },
    }).then(() => {
      this.addCommentItemId = "";
      this.commentInput = "";
    });
  }

  statusLabel(status: QuestionStatus): string {
    switch (status) {
      case QuestionStatus.PENDING:
        return this.$i18n.t("qa.statusPending").toString();
      case QuestionStatus.APPROVED:
        return this.$i18n.t("qa.statusApproved").toString();
      case QuestionStatus.DISMISSED:
        return this.$i18n.t("qa.statusDismissed").toString();
      case QuestionStatus.ANSWERED:
        return this.$i18n.t("qa.statusAnswered").toString();
      case QuestionStatus.ANSWERING:
        return this.$i18n.t("qa.statusAnswering").toString();
    }
  }

  get liveResourceCount(): number {
    return this.questions.filter(
      (question) =>
        question.status === QuestionStatus.ANSWERING &&
        question.broadcastId === this.selectedBroadcastId
    ).length;
  }
}
