





































































import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import Viewer from "@/spect8-core-vue/src/components/vico/ViewerSlot.vue";
import Mirror from "@/spect8-core-vue/src/components/mediaDevices/Mirror.vue";
import MediaSetup from "@/spect8-core-vue/src/components/mediaDevices/MediaSetup.vue";
import { Getter, Action } from "vuex-class";
import { Namespace } from "@/store/types";
import { RTCSlot } from "@/spect8-core-vue/src/stores/vico/RtcSlot";
import {
  QAAction,
  UpdateQuestionPayload,
  Question,
  QuestionStatus,
} from "@/components/Qa/types";
import {
  Conference,
  CoreMediaDevicesActions,
  CoreMediaDevicesGetters,
  CoreVicoActions,
} from "@/spect8-core-vue/src/types";
import { vicoService } from "@/spect8-core-vue/src/services/vico";

@Component({ components: { Viewer, Mirror, MediaSetup } })
export default class TestCall extends Vue {
  //   @Prop({ required: true }) readonly stream!: MediaStream;
  @Ref("video") videoElement!: HTMLMediaElement;

  @Action(`${Namespace.Qa}/${QAAction.UpdateQuestion}`)
  updateQuestion!: (payload: UpdateQuestionPayload) => Promise<void>;

  @Getter(`${Namespace.Conference}/testCall`)
  testCall!: RTCSlot | null;
  @Getter(`${Namespace.Conference}/testQuestion`)
  testQuestion!: Question;
  @Getter(`${Namespace.Conference}/testConference`)
  testConference!: Conference;
  @Action(`${Namespace.Conference}/setTestCallDialog`)
  setTestCallDialog!: (dialog: boolean) => void;
  @Action(`${Namespace.Conference}/clearTestCall`)
  clearTestCall!: () => void;
  @Getter(CoreMediaDevicesGetters.Stream)
  streamLocal?: MediaStream;

  get title() {
    return this.setupActive ? "Einstellungen" : "Testanruf";
  }

  setupActive = true;
  setupResolve?: (val: boolean) => void;
  setupReject?: (reason: any) => void;

  setup(): Promise<boolean> {
    // If the stream is already set up we resolve immediately
    if (this.streamLocal && this.streamLocal.active) {
      this.setupActive = false;
      this.videoActive = this.streamLocal
        .getVideoTracks()
        .some((track) => track.enabled);
      this.audioActive = this.streamLocal
        .getAudioTracks()
        .some((track) => track.enabled);
      return Promise.resolve(true);
    }

    this.setupActive = true;
    return new Promise((resolve, reject) => {
      this.setupResolve = resolve;
      this.setupReject = reject;
    });
  }

  finishSetup() {
    this.setupActive = false;
    this.videoActive =
      this.streamLocal?.getVideoTracks().some((track) => track.enabled) ??
      false;
    this.audioActive =
      this.streamLocal?.getAudioTracks().some((track) => track.enabled) ??
      false;
    if (this.setupResolve) this.setupResolve(true);
  }

  audioActive = true;
  videoActive = true;

  @Action(CoreMediaDevicesActions.ToggleVideo)
  toggleVideoAction!: () => void;
  @Action(CoreMediaDevicesActions.ToggleAudio)
  toggleAudioAction!: () => void;

  toggleVideo(): void {
    this.toggleVideoAction();
    this.videoActive = !this.videoActive;
  }

  toggleAudio(): void {
    this.toggleAudioAction();
    this.audioActive = !this.audioActive;
  }

  get stream(): MediaStream | undefined {
    return this.testCall?.mediaStream ?? undefined;
  }

  @Watch("stream")
  onStreamChange(stream: MediaStream) {
    if (this.videoElement && stream) {
      console.log("Setting new stream for remote viewer", stream);
      this.videoElement.srcObject = stream;
    }
  }

  @Watch("testCall", { deep: true })
  onTestCallChange(slot: RTCSlot | null) {
    if (slot) {
      if (slot.mediaStream?.active) {
        console.info("Starting verification interval");
        this.verifyForever();
      }
      // const ugh = slot.mediaStream?.getTracks();
      // if (ugh && ugh.length <= 0) {
      //   this.cleanup();
      //   console.info(
      //     "cleaning up because test call media stream has no tracks."
      //   );
      // }
    }
  }

  destroyed() {
    this.cleanup();
  }

  updateQuestionStatus(question: Question, status: QuestionStatus) {
    this.updateQuestion({
      questionId: question.id,
      patchDto: {
        status,
      },
    });
    this.$emit("close");
  }

  dismiss() {
    this.updateQuestionStatus(this.testQuestion, QuestionStatus.DISMISSED);
    // this.cleanup();
  }
  approve() {
    this.updateQuestionStatus(this.testQuestion, QuestionStatus.APPROVED);
    // this.cleanup();
  }

  @Action(CoreVicoActions.StopBroadcasting)
  stopBroadcasting!: () => void;

  async cleanup(): Promise<void> {
    console.info("CLEANING");
    this.stopBroadcasting();
    this.clearTestCall();
    clearInterval(this.verifyInterval);
  }

  async verifyTracks(retries = 5, delay = 1000): Promise<boolean> {
    return new Promise((resolve, reject) =>
      vicoService
        .getUserTracks(
          this.testQuestion.user.userId,
          this.testQuestion.user.tenantId
        )
        .then((tracks) => {
          if (tracks.trackinfos && tracks.trackinfos.length > 0)
            return resolve(true);
          if (retries <= 0) {
            console.info("no tracks :o");
            return reject(false);
          }

          setTimeout(async () => {
            resolve(this.verifyTracks(retries - 1, delay));
          }, delay);
        })
    );
  }

  verifyInterval?: ReturnType<typeof setInterval>;
  verifyForever(delay = 8000) {
    clearInterval(this.verifyInterval);
    this.verifyInterval = setInterval(async () => {
      try {
        let valid = await this.verifyTracks(1, 1000);
        if (!valid) this.cleanup();
      } catch (e) {
        this.cleanup();
        this.setTestCallDialog(false);
        this.$toast.error(
          "Die Verbindung mit dem Server ist fehlgeschlagen. Bitte versuchen Sie es erneut.",
          {
            timeout: 10000,
          }
        );
      }
    }, delay);
  }
}
