










import PageTemplate from "@/components/PageTemplate.vue";
import QaList from "@/components/Qa/QaList.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Namespace } from "@/store/types";
import { QAGetter } from "@/components/Qa/types";

@Component({
  components: {
    PageTemplate,
    QaList,
  },
})
export default class QaManagement extends Vue {
  @Getter(`${Namespace.Qa}/${QAGetter.SelectedBroadcastId}`)
  selectedBroadcastId!: string;

  RouteName = RouteName;
}
